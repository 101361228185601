import axios from "axios";
import getConfig from "next/config";
import { AddToWaitlistRequest, AddToWaitlistResponse } from "./apiTypes";

const { publicRuntimeConfig } = getConfig();

const axiosInstance = axios.create({
  baseURL: publicRuntimeConfig.NEXT_PUBLIC_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// API returns 200 with "$success: false" instead of http 404 code for
// resources that are not found (feature request from the client), so we
// want to manually fail requests on client side when we see failed request.
axiosInstance.interceptors.response.use((res) => {
  if (res.data.$success === false) {
    throw new Error("Request failed");
  }

  return res;
});

axiosInstance.interceptors.request.use((req) => {
  const token = localStorage.getItem("token");
  if (token && req.headers) {
    req.headers["Authorization"] = token;
  }
  return req;
});

export const addToWaitlist = ({
  data,
}: AddToWaitlistRequest): Promise<AddToWaitlistResponse> =>
  axiosInstance.post(`/web/waitlist/signup`, data).then((res) => res.data);

export default axiosInstance;
