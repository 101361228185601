import React from "react";
import NumberFormat from "react-number-format";
import { IMaskInput } from "react-imask";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import { Props, NumberFormatType } from "./types";
import Image from "next/image";

const Input = (
  {
    error,
    success,
    label,
    name,
    placeholder,
    type = "text",
    onChange,
    onFocus,
    onBlur,
    mask,
    format,
    className,
    value,
    disabled,
    thousandSeparator,
    calculatorInput,
    prefix,
    suffix,
    tooltip,
    isOptional,
    onValueChange,
    legend,
    allowEmptyFormatting,
    isNumericString = true,
    onKeyDown,
    autoComplete,
    min,
    max,
    noMargin = false,
    iconPosition,
    isDark,
  }: Props,
  ref: React.Ref<HTMLInputElement> | undefined,
) => {
  const errorClassess =
    "order-error-border outline-offset-2 bg-error-border bg-opacity-25 text-error-value border-error-border";

  const noErrorClassess =
    "border-grey-100 bg-grey-100 text-grey-800 read-only:text-grey-400 read-only:bg-grey-50 read-only:border-grey-50";

  return (
    <>
      <div
        className={`w-full  ${noMargin ? "mb-0" : "mb-6 md:mb-8"} ${
          calculatorInput ? "flex flex-row justify-between" : ""
        }`}
      >
        {label && (
          <label
            className={`flex items-center mb-2 text-lg font-bold ${
              isDark ? "text-white" : "text-grey-800"
            }  md:whitespace-nowrap`}
          >
            <span>
              {label} {isOptional && <span className="text-grey-600">(optional)</span>}
            </span>
            {tooltip && (
              <Tippy content={tooltip}>
                <span className="ml-2 min-h-[19px]  min-w-[19px]">
                  <Image width={19} height={19} alt="tooltip" src="/icons/tooltip.svg" />
                </span>
              </Tippy>
            )}
            <div className="relative w-6 h-6"></div>
          </label>
        )}
        {type === "inputmask" ? (
          <div className="relative">
            <IMaskInput
              className={`transition-opacity read-only:select-none duration-300 ease-in-out appearance-none block w-full px-4 md:px-6 py-5 text-lg border-2 ${
                error ? errorClassess : noErrorClassess
              } 
         rounded  placeholder-grey-500  outline-grey-200 ${className}`}
              placeholder={placeholder}
              type={type as NumberFormatType}
              name={name}
              onFocus={onFocus}
              onBlur={onBlur}
              mask={String(mask)}
              placeholderChar="_"
              lazy={false}
              unmask={true}
              value={value || ""}
              inputRef={() => ref}
              readOnly={disabled}
              onAccept={(value) => onValueChange && onValueChange(String(value))}
              prepare={(str: string) => str.toUpperCase()}
              prefix={prefix}
            />
            {suffix && (
              <div className={`absolute ${iconPosition ? iconPosition : "right-5 top-4"}`}>
                {suffix}
              </div>
            )}
            {success && (
              <div className="h-8 right-5 absolute-center-y">
                <Image src="/icons/success.svg" width={32} height={32} alt="success" />
              </div>
            )}
          </div>
        ) : mask || (thousandSeparator && !calculatorInput) ? (
          <div className="relative">
            <NumberFormat
              className={`transition-opacity read-only:select-none duration-300 ease-in-out appearance-none block w-full px-4 md:px-6 py-5 text-base xs:text-lg border-2  ${
                error ? errorClassess : noErrorClassess
              } 
          rounded  placeholder-grey-500  outline-grey-200 ${
            isDark && "bg-landing-blue text-white border-grey-600"
          } ${className}`}
              placeholder={placeholder}
              allowEmptyFormatting={allowEmptyFormatting}
              type={type as NumberFormatType}
              displayType="input"
              name={name}
              format={format}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              mask={mask}
              value={value || ""}
              readOnly={disabled}
              getInputRef={ref}
              allowNegative={false}
              decimalScale={2}
              isNumericString={isNumericString}
              defaultValue={undefined}
              thousandSeparator={thousandSeparator}
              prefix={prefix}
              onValueChange={({ value }) => onValueChange && onValueChange(value)}
            />
            {suffix && (
              <div className={`absolute ${iconPosition ? iconPosition : "right-5 top-5"}`}>
                {suffix}
              </div>
            )}
            {success && (
              <div className="h-8 right-5 absolute-center-y">
                <Image src="/icons/success.svg" width={32} height={32} alt="success" />
              </div>
            )}
          </div>
        ) : calculatorInput ? (
          <div className="relative flex">
            <NumberFormat
              className={`transition-opacity read-only:select-none duration-300 ease-in-out appearance-none flex w-full px-4 md:px-6 py-5 text-lg border-b-[1px] border-black
        placeholder-grey-500 ${className} bg-white !text-3xl !px-0 !py-0 !w-32 text-right `}
              placeholder={placeholder}
              allowEmptyFormatting={allowEmptyFormatting}
              type={type as NumberFormatType}
              displayType="input"
              name={name}
              format={format}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              mask={mask}
              value={value || ""}
              readOnly={disabled}
              getInputRef={ref}
              allowNegative={false}
              decimalScale={2}
              isNumericString={isNumericString}
              defaultValue={undefined}
              thousandSeparator={thousandSeparator}
              prefix={prefix}
              onValueChange={({ value }) => onValueChange && onValueChange(value)}
            />
            {suffix && (
              <div className={`absolute ${iconPosition ? iconPosition : "right-5 top-5"}`}>
                {suffix}
              </div>
            )}
          </div>
        ) : (
          <div className={`relative ${className}`}>
            <input
              className={`transition-opacity duration-300 ease-in-out appearance-none block w-full px-4 md:px-6 py-5 text-lg border-2 placeholder:-translate-y-0.4  ${
                error ? errorClassess : noErrorClassess
              } 
          rounded  placeholder-grey-500 outline-grey-200 pr-12 ${
            isDark && "bg-landing-blue text-white border-grey-600"
          } ${className}`}
              placeholder={placeholder}
              name={name}
              type={type}
              min={min}
              max={max}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              ref={ref}
              value={value || undefined}
              readOnly={disabled}
              onKeyDown={onKeyDown}
              autoComplete={autoComplete}
            />
            {suffix && (
              <div className={`absolute ${iconPosition ? iconPosition : "right-5 top-5"}`}>
                {suffix}
              </div>
            )}
            {success && (
              <div className="h-8 right-5 absolute-center-y">
                <Image src="/icons/success.svg" width={32} height={32} alt="success" />
              </div>
            )}
          </div>
        )}
        {legend}
        <div
          className={`mt-2 text-base transition-opacity duration-300 ease-in-out transform ${
            error ? "inline-block text-error-border opacity-100 " : "opacity-0"
          } ${calculatorInput ? "hidden" : ""}`}
        >
          {error}
        </div>
      </div>
      {calculatorInput && (
        <div
          className={`mt-2 text-base font-normal transition-opacity duration-300 ease-in-out transform ${
            error && calculatorInput ? "inline-block text-error-border opacity-100 " : "opacity-0"
          }`}
        >
          {error}
        </div>
      )}
    </>
  );
};

export default React.forwardRef(Input);
