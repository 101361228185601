import Input from "@/atoms/Input";
import Button from "@/atoms/Button";
import { useState, useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { addToWaitlist } from "@/services/api";
import { AxiosError } from "axios";
import { useSignUp } from "@/contexts/SignUpContext";
import { conversionSubmitLeadForm } from "@/components/shared/gtm";

const Signup = (): JSX.Element => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const { signUpViewRef } = useSignUp();

  const { mutate } = useMutation(addToWaitlist, {
    onSuccess: (data) => {
      setError("");
      setSuccess(true);
      conversionSubmitLeadForm();
    },
    onError: (err: AxiosError) => {
      setError("Something went wrong, please try again!");
    },
  });
  useEffect(() => {
    if (window.location.hash === "#signup") {
      setTimeout(() => {
        if (signUpViewRef.current) {
          signUpViewRef.current.scrollIntoView({ behavior: "auto" });
        }
      }, 600);
    }
  }, [signUpViewRef]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSignup = () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email address!");
      setSuccess(false);
      emailInputRef.current?.focus();
    } else {
      mutate({ data: { email } });
    }
  };

  const validateEmail = (email: string) => {
    const regex = /\S+@\S+\.\S+/;

    return regex.test(email);
  };

  const SignupButton = () => {
    return (
      <Button
        variant="primary"
        className="border-none transform hover:scale-105 rounded-full text-white w-full md:w-26 h-14 md:h-13 font-bold"
        onClick={handleSignup}
      >
        Sign up
      </Button>
    );
  };

  return (
    <section
      className="px-5 lg:px-11 xl:px-32 overflow-hidden"
      ref={signUpViewRef}
    >
      <div className="relative w-full flex justify-center items-center h-[30rem] md:h-[26rem] bg-gradient-to-tr from-navy from-50% md:from-55% to-magenta to-110% bg-navy rounded-3xl">
        <div
          className="absolute w-[300px] h-[300px] md:w-[30rem] md:h-[30rem] -bottom-[2rem] -left-3 md:-bottom-[10rem] md:-left-6"
          style={{
            background:
              "radial-gradient(50% 50% at 50% 50%, #2233FD 0%, rgba(34, 51, 253, 0.00) 100%)",
          }}
        ></div>

        <div className="w-full flex flex-col gap-8 text-center md:px-20 px-4 z-50">
          <h3 className="text-2xl lg:text-4xl text-white font-bold">
            Want to know more about Klink?
          </h3>
          <p className="text-xl text-light-white">
            Make a date with home ownership by joining our growing community
          </p>
          {!success && (
            <div className="flex flex-col md:flex-row justify-center gap-3 md:gap-5">
              <div className="relative">
                <Input
                  type="text"
                  placeholder="Enter your email address"
                  className="bg-transparentWhite border-transparent outline-grey-500 h-17 w-full md:w-[28rem] rounded-full placeholder-white text-white border-0 font-medium text-center md:text-left"
                  value={email}
                  onChange={handleInputChange}
                  ref={emailInputRef}
                />
                {error && (
                  <p className="text-error-value text-lg -mt-4">{error}</p>
                )}

                <div className="hidden md:block absolute top-2 right-2">
                  <SignupButton />
                </div>
              </div>

              <div className="md:hidden w-full flex justify-center">
                <SignupButton />
              </div>
            </div>
          )}
          {success && (
            <p className="text-white text-lg">
              Thanks for signing up! Please check your inbox.
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Signup;
