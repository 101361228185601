import Signup from "./Signup";
import Image from "next/image";
import { useWindowSize } from "@/shared/hooks/useWindowSize";
import Screen from "@/constants/screens";

const SignupForm = (): JSX.Element => {
  const { width } = useWindowSize();
  const imageWidth = 383;
  const imageHeight = width >= Screen.Tablet_768 ? 109 : 95;

  return (
    <div className="relative">
      <Signup />
      <div className="absolute right-[1rem] -bottom-[3.5rem] md:right-[13rem] md:-bottom-[4rem] lg:-right-10 lg:bottom-32 xl:right-12 xl:bottom-32">
        <Image
          src="/koach/shape.svg"
          alt="Shape image"
          objectFit="contain"
          priority
          width={imageWidth}
          height={imageHeight}
        />
      </div>
    </div>
  );
};

export default SignupForm;
